<div class="p-1" *ngIf="isDataLoaded">
  <div class="d--f jc--sb pb-1">
    <div class="grey-color f-700 fs-22">
      {{ action === "add" ? "Add New User" : "User Details" }}
    </div>
    <div>
      <mat-icon color="primary" class="pointer f-700" (click)="close()">close</mat-icon>
    </div>
  </div>
  <form [formGroup]="userFormGroup" (ngSubmit)="!isLoading && submitForm()">
    <div class="d--f ai--c jc--sb profile pb-2">
      <div class="d--f ai--c" *ngIf="action === 'edit'">
        <app-profile-image-preview [profileImage]="data?.user?.profileImage" [size]="8"></app-profile-image-preview>
        <div class="d--f fd--c ml-2">
          <div class="primary-color f-700 mt-1">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>Member ID</mat-label>
              <input formControlName="memberId" matInput type="text" readonly />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div *hasRole="['superadmin', 'primeadmin']">
      <div class="grey-color f-700 mt-2">Account Type</div>
      <div class="mt-1">
        <mat-checkbox color="primary" class="mr-2" formControlName="isAdmin" (change)="adminControlChanged($event)"><span
            class="grey-color">Admin</span></mat-checkbox>
        <mat-checkbox color="primary" formControlName="isPrimeAdmin" [disabled]="!userFormGroup.get('isAdmin').value"><span
            class="grey-color f-700">Prime</span></mat-checkbox>
      </div>
    </div>
    <div class="grey-color f-700 mt-2">Details</div>
    <div class="d--f fd--c">
      <div class="d--f mt-2">
        <mat-form-field color="primary" class="mr-2" appearance="outline">
          <mat-label>Title *</mat-label>
          <input formControlName="title" matInput type="text" />
        </mat-form-field>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>First Name *</mat-label>
          <input formControlName="firstName" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="d--f mt-2">
        <mat-form-field color="primary" class="mr-2" appearance="outline">
          <mat-label>Last Name *</mat-label>
          <input formControlName="lastName" matInput type="text" />
        </mat-form-field>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>E-mail id *</mat-label>
          <div class="email-input-container">
            <input formControlName="email" matInput type="text" />
            <span *ngIf="action === 'add'" class="email-domain">
              @{{ organization.domain }}
            </span>
          </div>
        </mat-form-field>
      </div>
      <!-- <div class="grey-color f-700 mt-2">Professional Details</div> -->
      <div class="d--f mt-2" *ngIf="!userFormGroup.get('isAdmin').value">
        <mat-form-field appearance="outline" class="mr-2">
          <mat-label>Hospitals *</mat-label>
          <mat-select formControlName="hospitals" multiple>
            <mat-option>
              <ngx-mat-select-search [formControl]="hospitalFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="null"
                [searching]="hospitalSearching"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let hospital of filteredHospitals" [value]="hospital._id" [disabled]="
                isHospital && resource && resource._id === hospital._id
              ">
              <div>
                {{ hospital.name }}
                <span class="grey-color fs-12">
                  ({{ hospital.city }})
                </span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Specialities *</mat-label>
          <mat-select formControlName="specialities" [multiple]="!isHospital">
            <mat-option *ngFor="let speciality of specialities" [value]="speciality._id">{{ speciality.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field color="primary" class="mr-2" appearance="outline" style="width: 100%">
          <mat-label>City *</mat-label>
          <input formControlName="city" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="grey-color f-700 mt-2" *ngIf="!userFormGroup.get('isAdmin').value">
        Documents
      </div>
      <div *ngIf="!userFormGroup.get('isAdmin').value">
        <div class="d--f ai--c mt-2">
          <button type="button" mat-stroked-button color="primary" (click)="fileInput.click()">
            Choose files
          </button>
          <div class="d--f ai--c ml-2 mr-2" *ngIf="isFileUploading">
            <mat-spinner class="mr-1" diameter="20"></mat-spinner>
            <span class="primary-color"> uploading... </span>
          </div>
          <div class="d--f ai--c ml-2" *ngIf="isFileDeleting">
            <mat-spinner color="warn" class="mr-1" diameter="20"></mat-spinner>
            <span class="warn-color"> deleting... </span>
          </div>
          <input type="file" multiple hidden #fileInput (change)="handleFileInputChange(fileInput.files)" />
        </div>
        <div class="mt-1">
          <mat-chip-list>
            <mat-chip *ngFor="let document of userFormGroup.get('documents').value" color="primary">
              <mat-icon color="primary" matChipAvatar>description</mat-icon>
              <span>
                {{ document.name }}
              </span>
              <mat-icon matChipRemove (click)="removeDocument(document)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div class="d--f f--g-1 mt-2" [ngClass]="{ 'jc--sb': action === 'edit', 'jc--fe': action === 'add' }">
      <button *ngIf="action == 'edit'" mat-raised-button color="primary" type="button" (click)="!isDeleteLoading && openDeleteConfirmation()"
        [style.opacity]="isDeleteLoading ? 0.5 : 1">
        Delete
        <mat-icon class="ml-2" *ngIf="isDeleteLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
      <button mat-raised-button color="primary" type="submit" [style.opacity]="isLoading ? 0.5 : 1">
        {{ action == "add" ? "Add" : "Update" }}
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>
<div *ngIf="!isDataLoaded" style="width: 67rem; height: 46.5rem">
  <div class="d--f jc--c ai--c h--100">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
</div>