<div class="p-1">
  <div class="d--f jc--sb bb-1 pb-1">
    <div class="grey-color f-700 fs-22">Add New Organization</div>
    <div>
      <mat-icon color="primary" class="pointer f-700" (click)="cancelForm()">close</mat-icon>
    </div>
  </div>
  <form [formGroup]="organizationFormGroup" (ngSubmit)="!isLoading && addOrganization()">
    <div class="grey-color mb-1 f-700 mt-2">Organization Type</div>
    <div style="margin-top: 1.5rem">
      <mat-radio-group formControlName="type" aria-label="Select an option" class="d--f ai--c">
        <mat-radio-button value="hospital" color="primary" class="mr-1">
          Hospital
        </mat-radio-button>
        <mat-radio-button color="primary" value="rep_company">
          Device Company
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- profile image -->
    <div class="mt-2" *ngIf="organizationFormGroup.get('type').value === 'rep_company'">
      <app-image-selector [image]="organizationFormGroup.get('profileImage')?.value" (uploaded)="imageUploaded($event)"
        (removed)="imageRemoved()"></app-image-selector>
    </div>
    <!-- Hospital Details-->
    <div class="grey-color pb-1 f-700 mt-2">Hospital/Company Details</div>
    <div class="d--f jc--sb mt-1">
      <div>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Hospital/Device Company *</mat-label>
          <mat-select formControlName="name" (selectionChange)="setDetails($event)">
            <mat-option>
              <ngx-mat-select-search [formControl]="nameFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="null"
                [searching]="resourceSearching"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="
                let resource of organizationFormGroup.get('type').value ===
                'hospital'
                  ? filteredHospitals
                  : filteredDeviceCompanies
              " [value]="resource">
              <div>
                {{ resource.name }}
                <span class="grey-color fs-12" *ngIf="organizationFormGroup.get('type').value === 'hospital'">
                  ({{ resource.city }})
                </span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Domain *</mat-label>
          <input formControlName="domain" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="mt-2">
      <div>
        <mat-form-field style="width: 100%" color="primary" appearance="outline">
          <mat-label>Website</mat-label>
          <input formControlName="website" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="mt-2">
      <div>
        <mat-form-field style="width: 100%" color="primary" appearance="outline">
          <mat-label>Description</mat-label>
          <input formControlName="description" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <!-- Office Details-->
    <div *ngIf="organizationFormGroup.get('type').value === 'hospital'">
      <div class="grey-color pb-1 f-700 mt-2">Office Address</div>
      <div class="d--f jc--sb mt-1">
        <div>
          <mat-form-field color="primary" appearance="outline">
            <mat-label>City *</mat-label>
            <input formControlName="city" matInput type="text" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Address *</mat-label>
            <input formControlName="address" matInput type="text" />
          </mat-form-field>
        </div>
      </div>
      <div class="d--f jc--sb mt-2">
        <div>
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Postal Code *</mat-label>
            <input formControlName="postalCode" matInput type="text" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Contact Number *</mat-label>
            <input mask="(000) 000-0000" formControlName="contactNumber" matInput type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d--f jc--fe f--g-1 mt-2">
      <button mat-raised-button color="primary" type="submit" [style.opacity]="isLoading ? 0.5 : 1">
        Add
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>