<div class="d--f jc--sb ai--c mt-2">
  <div class="f-700 fs-20">Unite Management</div>
  <div class="d--f ai--c">
    <div class="fs-14">
      <mat-form-field
        class="search-field mr-2"
        color="primary"
        appearance="outline"
      >
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input
          [(ngModel)]="searchTerm"
          (input)="onSearchChange()"
          matInput
          type="text"
        />
      </mat-form-field>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="openOrganizationForm($event)"
    >
      <span> Add </span>
    </button>
    <div class="d--f ai--c ml-2">
      <span class="primary-color fs-18 mr-1">View</span>
      <img
        src="assets/icons/Grid.svg"
        alt=""
        *ngIf="viewService.isGridView$ | async"
        class="pointer view-icon"
        (click)="viewService.toggleView()"
      />
      <img
        src="assets/icons/List.svg"
        alt=""
        *ngIf="!(viewService.isGridView$ | async)"
        class="pointer view-icon"
        (click)="viewService.toggleView()"
      />
    </div>
  </div>
</div>
<div class="d--f mt-2">
  <div
    class="p-1 mr-2 pointer"
    (click)="updateTypeFilter('hospital')"
    [ngClass]="{
      'active-border': (viewService.selectedType$ | async) === 'hospital'
    }"
  >
    Hospitals
  </div>
  <div
    class="p-1 pointer"
    (click)="updateTypeFilter('rep_company')"
    [ngClass]="{
      'active-border': (viewService.selectedType$ | async) === 'rep_company'
    }"
  >
    Companies
  </div>
</div>
<div *ngIf="isLoading" class="d--f jc--c mt-3">
  <mat-spinner color="primary" diameter="50"></mat-spinner>
</div>
<div
  *ngIf="
    !isLoading && (viewService.isGridView$ | async) && organizations.length > 0
  "
>
  <div id="card-view-container" class="mt-4 card-container">
    <my-rep-organization-card
      *ngFor="
        let organization of organizations;
        let i = index;
        trackBy: trackByFn
      "
      [organization]="organization"
      [pinkBg]="i % 2 === 0"
    ></my-rep-organization-card>
  </div>
  <mat-paginator
    [length]="totalLength"
    [pageSize]="(viewService.orgPaginationOptions$ | async)?.pageSize"
    [pageIndex]="(viewService.orgPaginationOptions$ | async)?.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
<div
  class="table-view-container"
  class="mt-2"
  [hidden]="
    !(
      !isLoading &&
      !(viewService.isGridView$ | async) &&
      dataSource.data.length > 0
    )
  "
>
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackByFn"
    *ngIf="dataSource.data.length > 0"
  >
    <ng-container matColumnDef="company">
      <th mat-sort-header="company" mat-header-cell *matHeaderCellDef>
        Hospital / Device Company
      </th>
      <hr />
      <td
        class="pointer"
        mat-cell
        *matCellDef="let organization"
        (click)="openOrganizationInfo(organization._id)"
      >
        <div class="d--f">
          <div class="f-400">{{ organization.name | titlecase }}</div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="registeredDate">
      <th mat-sort-header="registeredDate" mat-header-cell *matHeaderCellDef>
        Registered Date
      </th>
      <td mat-cell *matCellDef="let organization">
        <div class="d--f">
          <div>
            {{ organization.createdAt | date : "dd, MMM yyyy" }}
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="numberOfUsers">
      <th mat-sort-header="numberOfUsers" mat-header-cell *matHeaderCellDef>
        No. Of Users
      </th>
      <td mat-cell *matCellDef="let organization">
        <div class="d--f fd--c f--g-1">
          <div class="d--f">
            <div style="width: 7rem">Admin:</div>
            <div class="f-700">
              {{ organization.adminCount | number : "2.0" }}
            </div>
          </div>
          <div class="d--f">
            <div style="width: 7rem">User:</div>
            <div class="f-700">
              {{ organization.userCount | number : "2.0" }}
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-sort-header="status" mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let organization">
        <div class="d--f f--g-1">
          <my-rep-toggle
            [toggleDisabled]="
              isStatusUpdating && toggleIndexId === organization._id
            "
            [toggleChecked]="organization.isActive"
            (toggleChange)="updateStatus($event, organization)"
          ></my-rep-toggle>
          <span
            *ngIf="!(isStatusUpdating && toggleIndexId === organization._id)"
          >
            {{ organization.isActive ? "Active" : "Disabled" }}</span
          >
          <mat-spinner
            *ngIf="isStatusUpdating && toggleIndexId === organization._id"
            color="primary"
            diameter="20"
          ></mat-spinner>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="organizationTableCols"></tr>
    <tr mat-row *matRowDef="let row; columns: organizationTableCols"></tr>
  </table>
  <mat-paginator
    [length]="totalLength"
    [pageSize]="(viewService.orgPaginationOptions$ | async)?.pageSize"
    [pageIndex]="(viewService.orgPaginationOptions$ | async)?.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
<my-rep-empty-screen
  [options]="emptyScreenOptions"
  *ngIf="
    (viewService.isGridView$ | async)
      ? !isLoading && organizations.length === 0
      : !isLoading && dataSource.data.length === 0
  "
></my-rep-empty-screen>
