<div>
  <form [formGroup]="form">
    <img class="profile-img" *ngIf="form.get('profileImage')?.value?.location" [src]="form.get('profileImage')?.value?.location"
      alt="profile image" />

    <!-- Hospital Details-->
    <div class="section-title">
      {{ form.controls.type.value === "hospital" ? "Hospital" : "Company" }}
    </div>
    <div class="flex-container">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>
          {{ form.controls.type.value === "hospital" ? "Hospital" : "Company" }}
          *
        </mat-label>
        <mat-select formControlName="name" (selectionChange)="setDetails($event)">
          <mat-option>
            <ngx-mat-select-search [formControl]="nameFilterCtrl" [placeholderLabel]="'Search'" [noEntriesFoundLabel]="null"
              [searching]="resourceSearching"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="
              let resource of form.get('type').value === 'hospital'
                ? filteredHospitals
                : filteredDeviceCompanies
            " [value]="resource">
            <div>
              {{ resource.name }}
              <span class="grey-color fs-12" *ngIf="form.get('type').value === 'hospital'">
                ({{ resource.city }})
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Email Domain (e.g. "proxdis.com") *</mat-label>
        <input formControlName="domain" matInput type="text" />
      </mat-form-field>
    </div>
    <mat-form-field class="full-width-field" color="primary" appearance="outline">
      <mat-label>Website</mat-label>
      <input formControlName="website" matInput type="text" />
    </mat-form-field>
    <mat-form-field class="full-width-field" color="primary" appearance="outline">
      <mat-label>Description</mat-label>
      <input formControlName="description" matInput type="text" />
    </mat-form-field>
    <!-- Office Details-->
    <div *ngIf="form.get('type').value === 'hospital'">
      <div class="section-title">Office Address</div>
      <div class="flex-container">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>City *</mat-label>
          <input formControlName="city" matInput type="text" />
        </mat-form-field>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Address *</mat-label>
          <input formControlName="address" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="flex-container">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Postal Code *</mat-label>
          <input formControlName="postalCode" matInput type="text" />
        </mat-form-field>
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Contact Number *</mat-label>
          <input mask="(000) 000-0000" formControlName="contactNumber" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>