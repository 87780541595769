<div class="d--f jc--sb ai--c mt-2 mb-2">
  <div class="f-700 fs-20" *hasRole="'superadmin'">Users</div>
  <div class="f-700 fs-20" *hasRole="['admin', 'primeadmin']">
    Unite Management
  </div>
  <div class="d--f ai--c">
    <div class="fs-14">
      <mat-form-field
        class="search-field mr-2"
        color="primary"
        appearance="outline"
      >
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input
          [(ngModel)]="searchTerm"
          (input)="onSearchChange()"
          matInput
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="mr-2 add-user-btn">
      <button mat-raised-button [matMenuTriggerFor]="menu" color="primary">
        <span> Add</span>
        <mat-icon class="ml-2">expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="mt-1">
        <button mat-menu-item class="d--f ai--c" (click)="openUserForm('add')">
          <mat-icon color="primary">person</mat-icon>
          <span class="grey-color" style="vertical-align: middle"
            >Add User</span
          >
        </button>
        <button mat-menu-item (click)="openImportForm()">
          <mat-icon color="primary">group</mat-icon>
          <span class="grey-color" style="vertical-align: middle"
            >Bulk User</span
          >
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<hr />
<div class="d--f mt-2">
  <div
    class="p-1 mr-2 pointer"
    (click)="updateStatusFilter(true)"
    [ngClass]="{ 'active-border': (viewService.isActiveTabSelected$ | async) }"
  >
    Active
  </div>
  <div
    class="p-1 pointer"
    (click)="updateStatusFilter(false)"
    [ngClass]="{ 'active-border': !(viewService.isActiveTabSelected$ | async) }"
  >
    Disabled
  </div>
</div>
<div class="pt-2 pb-4" [hidden]="!(!isLoading && dataSource.data.length > 0)">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackByFn"
    *ngIf="dataSource.data.length > 0"
  >
    <ng-container matColumnDef="name">
      <th mat-sort-header="name" mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user">
        <div
          class="d--f ai--c profile pointer"
          (click)="openUserForm('edit', user)"
        >
          <app-profile-image-preview
            [profileImage]="user.profileImage"
            [size]="6"
          ></app-profile-image-preview>
          <div class="d--f fd--c f--g-1 ml-2">
            <div>{{ user.firstName }} {{ user.lastName }}</div>
            <div class="d--f ai--c">
              <div class="fs-14 f-300">Member ID # {{ user.memberId }}</div>
              <div>
                <mat-icon class="ml-1" color="primary">
                  {{
                    user.role.key === "admin" ? "person_add" : "person_outline"
                  }}
                </mat-icon>
              </div>
              <div>
                <mat-icon *ngIf="user.isPrimeAdmin" class="ml-1" color="primary"
                  >star</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let user">
        <div class="d--f f--g-1">
          <my-rep-toggle
            (click)="$event.stopPropagation()"
            [toggleDisabled]="false"
            [toggleChecked]="user.isActive"
            (toggleChange)="updateStatus($event, user)"
          ></my-rep-toggle>
          <mat-spinner
            *ngIf="isStatusUpdating && toggleIndexId === user._id"
            color="primary"
            diameter="20"
          ></mat-spinner>
          <span *ngIf="!(isStatusUpdating && toggleIndexId === user._id)">
            {{ user.isActive ? "Active" : "Disabled" }}
          </span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="userTableCols"></tr>
    <tr mat-row *matRowDef="let row; columns: userTableCols"></tr>
  </table>
  <mat-paginator
    [length]="totalLength"
    [pageSize]="(viewService.userPaginationOptions$ | async)?.pageSize"
    [pageIndex]="(viewService.userPaginationOptions$ | async)?.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
<my-rep-empty-screen
  [options]="emptyScreenOptions"
  *ngIf="!isLoading && dataSource.data.length == 0"
></my-rep-empty-screen>
<div *ngIf="isLoading" class="d--f jc--c mt-2">
  <mat-spinner color="primary" diameter="50"></mat-spinner>
</div>
