<div class="auth-page">
  <div class="auth-container">
    <div class="logo pointer" (click)="navigateSignIn()">
      <img src="../../../assets/images/logo.png" alt="logo" />
    </div>
    <div class="mb-4">
      <router-outlet></router-outlet>
    </div>
    <div class="footer-actions">
      <div class="mr-5 bb-1">
        <a href="mailto:support@proxdis.com">support@proxdis.com</a>
      </div>
      <div *ngIf="!hasRoute('reset-password') && !hasRoute('signup')" class="pointer" (click)="goToResetScreen()">
        Forgot password?
      </div>
    </div>
  </div>
  <div class="auth-promo f--1"></div>
</div>