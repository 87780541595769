<div class="header-container">
  <div class="logo pointer" (click)="navigateToHome()">
    <img src="../../../assets/images/logo.png" alt="logo" />
  </div>
  <div class="d--f ai--c header-actions f--1 f--g-2 pb-2"></div>
  <div class="d--f ai--c profile pb-2">
    <div class="d--f fd--c ai--fe mr-2">
      <div class="primary-color f-700 fs-20">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="grey-color mt-1">Member ID#{{ user.memberId }}</div>
      <div class="primary-color f-700">
        {{ user.role.name }}
        <span *ngIf="user.isPrimeAdmin">(Prime)</span>
      </div>
      <div class="primary-color f-700" *ngIf="organization$ | async as organization">
        {{ organization.name | titlecase }}
      </div>
    </div>
    <app-profile-image-preview [profileImage]="user.profileImage" [size]="8"></app-profile-image-preview>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon color="primary">expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="navigateToProfile()">
          <span>Profile</span>
        </button>
        <button mat-menu-item (click)="openAgreement('tAndC')" *hasRole="['superadmin']">
          <span>Terms & Privacy Policy</span>
        </button>
        <button mat-menu-item (click)="changePassword()">
          <span>Change Password</span>
        </button>
        <button mat-menu-item>
          <span>Help</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<div class="home-container">
  <router-outlet></router-outlet>
</div>