<div class="card pointer pink-bg h--100" [ngClass]="{ 'pink-bg': pinkBg }" (click)="openOrganizationInfo(organization._id)">
  <div class="fs-18 mb-1 d--f ai--c">
    <span>
      {{ organization.name }}
    </span>
  </div>
  <hr />
  <div class="d--f fd--c p-0">
    <div class="d--f jc--sb mt-1">
      <div>
        <div class="f-300 mb-1">Status</div>
        <div *ngIf="!isStatusUpdating" class="fs-18">
          {{ organization.isActive ? "Active" : "Disabled" }}
        </div>
        <div *ngIf="isStatusUpdating">
          <mat-spinner color="primary" diameter="20"></mat-spinner>
        </div>
      </div>
      <div>
        <div class="f-300 mb-1">Registered Date</div>
        <div class="fs-18">
          {{ organization.createdAt | date : "dd, MMM yyyy" }}
        </div>
      </div>
    </div>
    <div class="d--f mt-2">
      <div class="d--f ai--c mr-2">
        <mat-icon class="mr-1">person_add</mat-icon>
        <span>
          {{ organization.adminCount || 0 }}
        </span>
      </div>
      <div class="d--f ai--c">
        <mat-icon class="mr-1">person_outline</mat-icon>
        <span>
          {{ organization.userCount || 0 }}
        </span>
      </div>
    </div>
    <div class="d--f jc--sb mt-2">
      <div class="d--f ai--c">
        <mat-icon>alternate_email</mat-icon>
        <span>
          {{ organization.domain }}
        </span>
      </div>
      <div class="d--f ai--c f--g-1">
        <button mat-stroked-button color="primary" (click)="goLive($event)" [disabled]="!organization.isActive">
          Go Live
        </button>
        <my-rep-toggle (click)="$event.stopPropagation()" [toggleDisabled]="isStatusUpdating" (toggleChange)="updateStatus($event, organization)"
          [toggleChecked]="organization.isActive"></my-rep-toggle>
      </div>
    </div>
  </div>
</div>